import { sign as signJWT } from "jsonwebtoken";

import { Unit } from "./types";

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // Force the formatted value to be an integer
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatValue = (value: number | string, unit: Unit) => {
  if (value == null) {
    return "N/A";
  }

  if (unit === "string") {
    return value;
  }

  if (unit === "usd") {
    return usdFormatter.format(value as number);
  }

  if (unit === "percentage") {
    return `${value}%`;
  }

  if (unit === "stars") {
    return `${value} stars`;
  }

  if (unit === "hours") {
    return `${value} hrs`;
  }

  return value.toString();
};

const METABASE_SITE_URL = process.env.NEXT_PUBLIC_METABASE_SITE_URL;
const METABASE_SECRET_KEY = process.env.NEXT_PUBLIC_METABASE_SECRET_KEY;

export enum MetabaseDashboard {
  // 2024 Q4
  CURRENT_STATS_ASSESSMENT_COMPLETION_2024_Q4 = 400,
  CURRENT_STATS_CHARTS_CLOSED_2024_Q4 = 403,
  CURRENT_STATS_MESSAGING_2024_Q4 = 404,
  CURRENT_STATS_OUTCOMES_2024_Q4 = 406,
  CURRENT_STATS_SEEN_AT_THERAPIST_ASSIGNED_FREQUENCY_2024_Q4 = 408,
  CURRENT_STATS_SCHEDULED_TO_THERAPIST_ASSIGNED_FREQUENCY_2024_Q4 = 407,
  CURRENT_STATS_AVERAGE_WEEKLY_UTILIZATION_2024_Q4 = 401,
  CURRENT_STATS_TREATMENT_ADHERENCE_V6_2024_Q4 = 411,
  CURRENT_STATS_TREATMENT_ADHERENCE_V18_2024_Q4 = 412,
  CURRENT_STATS_TREATMENT_ADHERENCE_12_SESSIONS_2024_Q4 = 383,
  CURRENT_STATS_TREATMENT_CONQUERORS_2024_Q4 = 410,
  CURRENT_STATS_SUSTAINED_CONQUERORS_2024_Q4 = 409,
  CURRENT_STATS_CANCELLATION_RESCHEDULE_RATE_2024_Q4 = 402,
  CURRENT_STATS_MESSAGE_RESPONSIVENESS_2024_Q4 = 421,
  CURRENT_STATS_MESSAGE_FREQUENCY_2024_Q4 = 422,
}

export const goToMetabaseDashboard = (
  dashboard: MetabaseDashboard,
  params: Record<string, string | number>
) => {
  const payload = {
    resource: { dashboard },
    params,
    exp: Math.round(Date.now() / 1000) + 30 * 60, // 30 mins
  };

  const token = signJWT(payload, METABASE_SECRET_KEY);

  window.open(
    `${METABASE_SITE_URL}/embed/dashboard/${token}#titled=false&bordered=false`
  );
};
