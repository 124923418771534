import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Unit } from "../types";
import { formatValue } from "../utils";

const SimpleMetric = ({
  title,
  tooltipText,
  value,
  unit,
  timePeriod,
  onChangeTimePeriod,
  onViewDetails,
  isLoading,
  reachedGoal,
}: {
  title: string;
  tooltipText: string;
  value: number | string;
  unit: Unit;
  onViewDetails?: () => void;
  timePeriod?: string;
  onChangeTimePeriod?: (timePeriod: string) => void;
  isLoading?: boolean;
  reachedGoal?: boolean;
}) => {
  return (
    <Box component={Paper} p={2} flex={1} height="100%">
      <Stack direction="row" spacing={1} alignItems="center" mb={1}>
        <Typography variant="overline">{title}</Typography>

        {tooltipText ? (
          <Tooltip
            title={
              <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                {tooltipText}
              </Typography>
            }
            arrow
          >
            <InfoOutlined color="action" fontSize="small" />
          </Tooltip>
        ) : null}
      </Stack>

      <Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {onChangeTimePeriod ? (
            <TextField
              select
              value={timePeriod}
              size="small"
              onChange={(e) => onChangeTimePeriod(e.target.value)}
            >
              <MenuItem value="quarter">QTD</MenuItem>
              <MenuItem value="year">YTD</MenuItem>
              <MenuItem value="all">All</MenuItem>
            </TextField>
          ) : null}
          {!reachedGoal ? (
            <Typography variant="h4">
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                formatValue(value, unit)
              )}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                padding: 2,
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography variant="body1">
                  You&apos;re doing a great job signing notes on time! Keep it
                  up 🎉
                </Typography>
              )}
            </Box>
          )}
        </Stack>

        {onViewDetails ? (
          <Box sx={{ mt: 2 }}>
            <Button size="small" variant="outlined" onClick={onViewDetails}>
              View details
            </Button>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box component={Paper} p={2} flex={1} height="100%">
      <Typography variant="overline">
        <Skeleton />
      </Typography>

      <Typography variant="h4">
        <Skeleton />
      </Typography>

      <Typography variant="h3">
        <Skeleton />
      </Typography>
    </Box>
  );
};

const Failure = ({ message }: { message: string }) => {
  return (
    <Box component={Paper} p={2} flex={1} height="100%">
      <Typography>Failed to load data: {message ?? "Unknown error"}</Typography>
    </Box>
  );
};

SimpleMetric.Loading = Loading;
SimpleMetric.Failure = Failure;

export default SimpleMetric;
