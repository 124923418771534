import { Box, Typography } from "@mui/material";
import Image from "next/image";
import React, { useState } from "react";

interface ImpactBadgeProps {
  frontUrl: string;
  backUrl: string;
  title: string | React.ReactNode;
  count: number | string;
  hasBackground?: boolean;
}

const ImpactBadge: React.FC<ImpactBadgeProps> = ({
  frontUrl,
  backUrl,
  title,
  count,
  hasBackground = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        paddingY: "16px",
        paddingX: "10px",
        minWidth: "150px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: hasBackground
          ? `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 4.23%, rgba(255, 255, 255, 0.70) 79.7%)`
          : "none",
        borderRadius: "16px",
        perspective: "1000px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          width: "122px",
          height: "142px",
          position: "relative",
          transformStyle: "preserve-3d",
          transition: "transform 0.8s",
          transform: isHovered ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {count && (typeof count === "number" ? count !== 0 : count !== "0") ? (
          <Box
            sx={{
              position: "absolute",
              top: "16px",
              right: "4px",
              width: "auto",
              height: "28px",
              padding: "6px",
              minWidth: "28px",
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "11px",
              fontWeight: "bold",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              zIndex: 1,
              opacity: isHovered ? 0 : 1,
              transition: "opacity 0.4s ease-in-out",
            }}
          >
            {count}
          </Box>
        ) : null}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
          }}
        >
          <Image
            src={frontUrl}
            alt={title as string}
            layout="fill"
            objectFit="cover"
            style={{ borderRadius: "16px" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <Image
            src={backUrl}
            alt={title as string}
            layout="fill"
            objectFit="cover"
            style={{ borderRadius: "16px" }}
          />
        </Box>
      </Box>

      <Typography
        variant="subtitle1"
        textAlign="center"
        fontWeight="bold"
        lineHeight="16.8px"
        style={{ fontSize: "12px" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default ImpactBadge;
