import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { isEmpty } from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import { ISimpleMetric } from "../../types";
import SimpleMetric from "../SimpleMetric";

export const useAllTimeMembersMetric = (
  clinicianEmail: string
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "clinician-current-stats",
      "all-time-member-metric",
      "2024-q4",
      clinicianEmail,
    ],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2024/q4/current_stats/all_time_members`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
    }
  );
};

const AllTimeMembersMetric = ({
  clinicianEmail,
}: {
  clinicianEmail: string;
}) => {
  const { data, isLoading, error } = useAllTimeMembersMetric(clinicianEmail);

  if (error) {
    return <SimpleMetric.Failure message={error?.message} />;
  }

  if (isLoading) {
    return <SimpleMetric.Loading />;
  }

  if (isEmpty(data)) {
    return null;
  }

  return (
    <SimpleMetric
      title={data.title}
      tooltipText={data.tooltip_text}
      value={data.value}
      unit={data.unit}
    />
  );
};

export default AllTimeMembersMetric;
