import {
  format,
  formatInTimeZone,
  utcToZonedTime,
  zonedTimeToUtc,
} from "date-fns-tz";

// Turns "13:00" into "1:00pm"
const formatTime = (time: string, timezone: string) => {
  const [hour, minute] = time.split(":");
  const utcDate = zonedTimeToUtc(
    new Date(2000, 0, 1, +hour, +minute),
    timezone
  );
  const zonedDate = utcToZonedTime(utcDate, timezone);
  return format(zonedDate, "h:mmaaa", { timeZone: timezone });
};

const sortTimes = (times: { start: string; end: string }[]) =>
  times.sort((a, b) => {
    const timeA = a.start.split(":").map(Number);
    const timeB = b.start.split(":").map(Number);
    return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1]);
  });

// Input:
// {"sunday":[{"start":"09:00","end":"17:00"}],..."saturday":[{"start":"09:00","end":"17:00"}]}
//
// Output:
// Sunday
// 9:00am-5:00pm PDT
// ...
// Saturday
// 9:00am-5:00pm PDT
export const formatSchedulingPreferences = (
  preferences: Record<
    string,
    {
      start: string;
      end: string;
    }[]
  > | null,
  timezone: string
): string | null => {
  if (!preferences) {
    return null;
  }

  return [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ].reduce((memo, dow) => {
    let scheduleForDayOfWeek = "";

    const sortedTimes = sortTimes(preferences?.[dow] ?? []);

    sortedTimes.forEach(({ start, end }) => {
      const startTime = formatTime(start, timezone);
      const endTime = formatTime(end, timezone);
      scheduleForDayOfWeek += `${startTime}-${endTime} ${formatInTimeZone(
        new Date(),
        timezone,
        "zzz"
      )}\n`;
    });

    if (!scheduleForDayOfWeek) {
      return memo;
    }

    const humanizedDayOfWeek = dow.charAt(0).toUpperCase() + dow.slice(1);

    return `${memo}${humanizedDayOfWeek}\n${scheduleForDayOfWeek}\n`;
  }, "");
};
