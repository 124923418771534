import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { ISimpleMetric } from "@features/clinician-progress/types";
import { useQuery, UseQueryResult } from "react-query";

export const useActiveMembersNextSessionBooked = (
  clinicianEmail: string
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "clinician-current-stats",
      "active-member-next-session-booked-metric",
      "2024-q4",
      clinicianEmail,
    ],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2024/q4/current_stats/active_members_next_session_booked`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
    }
  );
};
