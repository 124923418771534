import { useAllTimeHoursMetric } from "@features/clinician-progress/components/MilestoneMetrics";
import { useClinicianTreatmentConquerorsMetric } from "@features/clinician-progress-v2/components/outcomes-and-bonus/TreatmentConquerorsMetric";
import { useAllTimeMembersMetric } from "@features/clinician-progress-v2/components/scheduling/AllTimeMemberMetric";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Box,
  Button,
  Divider,
  Skeleton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import Lottie from "react-lottie-player";

import { useActiveMembersNextSessionBooked } from "../hooks/useActiveMembersNextSessionBooked";
import ImpactDialogue from "./ImpactDialogue";

interface ImpactProgressBannerProps {
  open: boolean;
  onClose: () => void;
  message: React.ReactNode;
}

const ImpactProgressBanner: React.FC<ImpactProgressBannerProps> = ({
  open,
  onClose,
  message,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        mt: { sm: 4, lg: 2 },
        ml: { sm: 0, lg: 18 },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Alert
          severity="info"
          sx={{
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%), 
                         linear-gradient(to right, #DAC0FE 0%, #FCE2B6 45.78%, #AEE2E6 91.55%)`,
            color: "#000",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
            zIndex: 10,
          }}
          icon={false}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "16.8px",
                letterSpacing: "0.96px",
                whiteSpace: "nowrap",
              }}
            >
              IMPACT UPDATE
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                ml: { sm: 30, lg: 50 },
              }}
            >
              <Button
                onClick={onClose}
                variant="text"
                sx={{
                  textTransform: "none",
                  color: "#7279EE",
                  fontWeight: "bold",
                }}
              >
                Dismiss
              </Button>
              <Link href="/progress" passHref>
                <Button
                  onClick={onClose}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    background: "#7279EE",
                    color: "#fff",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  View Details
                </Button>
              </Link>
            </Stack>
          </Stack>

          {message}
        </Alert>
        <Box
          sx={{
            position: "absolute",
            top: "-200px",
            left: "-200px",
            width: "600px",
            height: "600px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zindex: -10,
          }}
        >
          <Lottie
            path="/images/impact-badges/fireworks.json"
            loop={false}
            play
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "-280px",
            right: "-180px",
            width: "600px",
            height: "600px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zindex: -10,
            transform: "scaleY(-1)",
          }}
        >
          <Lottie
            loop={false}
            path="/images/impact-badges/fireworks.json"
            play
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Box>
    </Snackbar>
  );
};

export interface Badge {
  title: string;
  imgUrl: string;
}

export interface Change {
  title: string;
  change: number;
  imgUrl: string;
}
const useMetricsComparison = (
  metrics: { [key: string]: number },
  badges: Badge[]
) => {
  const [changes, setChanges] = useState<Change[]>([]);
  const [hasPositiveChanges, setHasPositiveChanges] = useState(false);
  const [pendingMetrics, setPendingMetrics] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const savedMetricsRaw = localStorage.getItem("impactMetricsProgress");

    const savedMetrics: { [key: string]: number } = savedMetricsRaw
      ? JSON.parse(savedMetricsRaw)
      : {};
    const newChanges: Change[] = [];
    let positiveChangeDetected = false;

    Object.keys(metrics).forEach((key, index) => {
      const currentValue = metrics[key];
      if (currentValue === undefined || currentValue === null) return;

      const savedValue = savedMetrics[key] || 0;
      const badge = badges[index];

      if (currentValue !== savedValue) {
        const change = currentValue - savedValue;

        if (change > 0) {
          newChanges.push({
            title: badge?.title || "",
            change,
            imgUrl: badge?.imgUrl || "",
          });
          positiveChangeDetected = true;
        }
        savedMetrics[key] = currentValue;
      }
    });

    setPendingMetrics({ ...savedMetrics });
    setChanges(newChanges);
    localStorage.setItem("impactMetricsProgress", JSON.stringify(savedMetrics));

    setHasPositiveChanges(positiveChangeDetected);
  }, [metrics, badges]);

  return { changes, hasPositiveChanges, pendingMetrics };
};

const ImpactBar = ({ clinicianEmail }: { clinicianEmail: string }) => {
  const [openExpandDialog, setOpenExpandDialog] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const {
    data: allTimeCompletedData,
    isLoading: isLoadingAllTimeCompleted,
    error: allTimeCompletedError,
  } = useAllTimeHoursMetric(clinicianEmail);

  const {
    data: allActiveMembersData,
    isLoading: isLoadingAllActiveMembers,
    error: allActiveMembersError,
  } = useActiveMembersNextSessionBooked(clinicianEmail);

  const {
    data: allTimeMembersData,
    isLoading: isLoadingAllTimeMembers,
    error: allTimeMembersError,
  } = useAllTimeMembersMetric(clinicianEmail);

  const {
    data: allConquerorsData,
    isLoading: isLoadingAllConquerors,
    error: allConquerorsError,
  } = useClinicianTreatmentConquerorsMetric(clinicianEmail, "all-time");

  const metrics = useMemo(() => {
    if (
      !isLoadingAllActiveMembers &&
      !isLoadingAllTimeMembers &&
      !isLoadingAllConquerors
    ) {
      return {
        conquerors: allConquerorsData?.value || 0,
        active: allActiveMembersData?.value || 0,
        members: allTimeMembersData?.value || 0,
      };
    }
    return {};
  }, [
    allActiveMembersData,
    allTimeMembersData,
    allConquerorsData,
    isLoadingAllActiveMembers,
    isLoadingAllTimeMembers,
    isLoadingAllConquerors,
  ]);

  const badgesNew = useMemo(
    () => [
      {
        title: "LIFE CHANGING OUTCOMES",
        imgUrl: "/images/impact-badges/life-changing-outcomes-small.png",
      },
      {
        title: "ACTIVELY ENGAGED MEMBERS",
        imgUrl: "/images/impact-badges/active-members-small.svg",
      },
      {
        title: "HELPED ON THEIR THERAPY JOURNEY",
        imgUrl: "/images/impact-badges/helped-journey-small.png",
      },
    ],
    []
  );

  const savedBadges = [
    {
      title: "LIFE CHANGING OUTCOMES",
      value: allConquerorsData?.value || 0,
      imgUrl: "/images/impact-badges/life-changing-outcomes-small.png",
      tooltipText: "Life changing outcomes",
    },
    {
      title: "ACTIVELY ENGAGED MEMBERS",
      value: allActiveMembersData?.value || 0,
      imgUrl: "/images/impact-badges/active-members-small.svg",
      tooltipText: "Actively engaged members",
    },
    {
      title: "HELPED ON THEIR THERAPY JOURNEY",
      value: allTimeMembersData?.value || 0,
      imgUrl: "/images/impact-badges/helped-journey-small.png",
      tooltipText: "Helped on their therapy journey",
    },
    {
      title: "DIRECT THERAPY HOURS",
      value: Math.round(allTimeCompletedData?.value || 0),
      imgUrl: "/images/impact-badges/therapy-hours-small.svg",
      tooltipText: "Direct therapy hours",
    },
  ];

  const { changes, hasPositiveChanges, pendingMetrics } = useMetricsComparison(
    metrics || {},
    badgesNew
  );

  useEffect(() => {
    if (hasPositiveChanges) {
      setShowBanner(true);
    }
  }, [hasPositiveChanges]);

  if (
    isLoadingAllTimeCompleted ||
    isLoadingAllTimeMembers ||
    isLoadingAllActiveMembers ||
    isLoadingAllConquerors
  ) {
    return <Skeleton width="460px" height="38px" />;
  }

  if (
    allTimeCompletedError ||
    allTimeMembersError ||
    allConquerorsError ||
    allActiveMembersError
  ) {
    return <Typography>Failed to load data: Unknown error</Typography>;
  }

  if (
    isEmpty(allTimeCompletedData) ||
    isEmpty(allTimeMembersData) ||
    isEmpty(allConquerorsData) ||
    isEmpty(allActiveMembersData)
  ) {
    return null;
  }

  const allTimeCompletedValue = Math.round(
    allTimeCompletedData.value || 0
  ).toLocaleString();

  const handleOpen = () => {
    setOpenExpandDialog(true);
    setShowBanner(false);
  };

  const handleClose = () => {
    setOpenExpandDialog(false);
  };

  const handleDismissBanner = () => {
    setShowBanner(false);
    localStorage.setItem(
      "impactMetricsProgress",
      JSON.stringify(pendingMetrics)
    );
  };

  return (
    <>
      {showBanner && changes.length > 0 && (
        <>
          <ImpactProgressBanner
            open={showBanner}
            onClose={handleDismissBanner}
            message={
              <Box
                sx={{
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <Divider
                  sx={{
                    width: "100%",
                    background: `linear-gradient(to right, #8E93F1 0%, #00A3AD 100%)`,
                    height: "2px",
                    mb: 1,
                  }}
                />
                <Stack>
                  {changes.map(({ title, change, imgUrl }) => (
                    <Stack
                      key={title}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ padding: "4px 0" }}
                    >
                      <Box
                        component="img"
                        src={imgUrl}
                        alt={title}
                        sx={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                      />

                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "19.6px",
                          letterSpacing: "1.12px",
                        }}
                      >
                        +{change} {title}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            }
          />
        </>
      )}
      <Box>
        {!showBanner || changes.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
              borderRadius: "8px",
              border: "1px solid #FAD392",
              background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%), /* Top to bottom fade */
        linear-gradient(to right, #DAC0FE 0%, #FCE2B6 45.78%, #AEE2E6 91.55%)`,
              boxShadow: "4px 4px 0px 0px rgba(114, 121, 238, 0.20)",
              height: "46px",
              minWidth: "544px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#000",
              }}
            >
              YOUR IMPACT!
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              sx={{
                flex: 1,
                justifyContent: "flex-start",
                padding: "4px 30px",
              }}
            >
              {savedBadges.map((badge) => (
                <Box
                  key={badge.title}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px 8px",

                    borderRadius: "24px",
                    gap: "4px",
                    border: `1px solid var(--Brand-colors-Indigo-100, #E3E4FC)`,
                    background: `linear-gradient(0deg, #7279EE -3.12%, #B580FC 48.12%)`,
                  }}
                >
                  <Tooltip
                    title={badge.tooltipText}
                    key={badge.tooltipText}
                    placement="top"
                  >
                    <Box
                      component="img"
                      src={badge.imgUrl}
                      alt="badge"
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </Tooltip>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    {badge.value}
                  </Typography>
                </Box>
              ))}
            </Stack>

            <Button
              endIcon={<ExpandMoreIcon />}
              sx={{
                background: "#6E76EE",
                color: "#fff",
                padding: "4px 12px",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
                "&:hover": {
                  background: "#7279EE",
                },
              }}
              onClick={handleOpen}
            >
              Expand
            </Button>
          </Box>
        ) : (
          <Box width="460px" height="46px" />
        )}
      </Box>

      {openExpandDialog ? (
        <ImpactDialogue
          allTimeMembersValue={allTimeMembersData.value}
          allActiveMembersValue={allActiveMembersData.value}
          allTimeCompletedValue={allTimeCompletedValue || "0"}
          allConquerorsValue={allConquerorsData.value}
          isOpen={openExpandDialog}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
};

export default ImpactBar;
