import { useUser } from "@core/services/nocd-api/clinician";
import useClinicianSuccessionFlag from "@core/services/nocd-api/clinicians/queries/useClinicianSuccessionFlag";
import Loading from "@core/ui/Loading";
import MemberActivity from "@features/clinician-dashboard/components/MemberActivity";
import NewMessages from "@features/clinician-dashboard/components/NewMessages";
import Sessions from "@features/clinician-dashboard/components/SessionsV2";
import ClinicianTasks from "@features/clinician-tasks/components/ClinicianTasks";
import FeedbackDialog from "@features/clinicians/components/NotoFeedbackDialog";
import MemberFinder from "@features/member-finder/components/MemberFinder";
import PlanOfSuccessionMembers from "@features/plan-of-succession/components/PlanOfSuccessionMembers";
import { PLAN_OF_SUCCESSION_STATUSES } from "@features/plan-of-succession/constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useDismissNotoClinicianFeedback } from "../hooks/useDismissNotoFeedback";
import useNotoFeedbackForm from "../hooks/useNotoFeedbackForm";
import ClinicianRecommendedHours from "./ClinicianRecommendedHours";
import ImpactBar from "./ImpactBar";
import Metrics from "./Metrics";
import MetricsWithTreatmentAdherence from "./MetricsWithTreatmentAdherence";
import SessionCounter from "./SessionCounter";
import ThoughtOfTheDay from "./ThoughtOfTheDay";
import QuarterWeekUtilization from "./WeekUtilization";

const THERAPIST_CONQUERER_MILESTONES = [1, 10, 50, 100, 500, 1000];

const badgeIconLink = {
  1: "/images/therapist-badges/current-one.svg",
  10: "/images/therapist-badges/current-ten.svg",
  50: "/images/therapist-badges/current-fifty.svg",
  100: "/images/therapist-badges/current-one-hundred.svg",
  500: "/images/therapist-badges/current-five-hundred.svg",
  1000: "/images/therapist-badges/current-one-thousand.svg",
};

const AchievementsBanner = ({
  badgeCount,
  dismissAchievementsBanner,
}: {
  badgeCount: number;
  dismissAchievementsBanner: () => void;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      borderRadius={4}
      padding={2}
      mt={2}
      sx={{
        background: "linear-gradient(180deg, #EAF0F9 0%, #DCE7F8 100%)",
        maxWidth: "850px",
      }}
    >
      <Image src={badgeIconLink[badgeCount] as string} width={50} height={50} />

      <Box flexGrow={1}>
        <Typography fontWeight="bold" variant="body1">
          Congratulations on achieving a new Conqueror milestone!
        </Typography>
        <Typography variant="body2">
          You&apos;re making a real difference with life-changing care.
        </Typography>
      </Box>
      <Box display="flex" gap={2}>
        <Button
          type="button"
          onClick={() => dismissAchievementsBanner()}
          sx={{
            color: "#444AC6",
          }}
        >
          Dismiss
        </Button>
        <Link href="/progress" passHref>
          <Button
            fullWidth
            size="small"
            component="a"
            target="_blank"
            variant="contained"
            onClick={() => dismissAchievementsBanner()}
            sx={{
              background: "#6E76EE",
            }}
          >
            Check it out
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

const getGreeting = () => {
  const hour = new Date().getHours();

  if (hour < 12) {
    return "Good morning";
  }
  if (hour < 18) {
    return "Good afternoon";
  }
  return "Good evening";
};

export default function Dashboard() {
  const flags = useFlags();

  const { data: user } = useUser();
  const { mutateAsync: dismissFeedback } = useDismissNotoClinicianFeedback();
  const { data: showFeedbackForm } = useNotoFeedbackForm(user?.email);
  const isShowingFeedbackForm = showFeedbackForm?.isShowing;
  const shouldShowFeedbackForm = Boolean(flags?.enableNotoClinicianFeedback);
  const showShowImpactBar = Boolean(flags?.enableImpactBar);
  const { data: successionFlag } = useClinicianSuccessionFlag(user?.email);

  const [feedbackDialogueOpen, setFeedbackDialogueOpen] = useState(false);

  useEffect(() => {
    if (isShowingFeedbackForm && shouldShowFeedbackForm) {
      setFeedbackDialogueOpen(isShowingFeedbackForm);
    } else {
      setFeedbackDialogueOpen(false);
    }
  }, [isShowingFeedbackForm, shouldShowFeedbackForm]);

  const handleClose = () => {
    setFeedbackDialogueOpen(false);

    const payload = { clinician_email: user.email };
    return toast.promise(dismissFeedback(payload), {
      loading: "Dismissing feedback...",
      success: "Feedback dismissed",
      error: (error) => `Error dismissing feedback: ${error.message}`,
    });
  };

  const isSplitRole = Boolean(
    user?.current_clinician_role === "Split-Role Clinician"
  );
  const badgeCount = user?.conquerer_achievement_count;

  const shouldShowAchievementsBanner =
    Boolean(flags?.enableTherapistBadgeAndAchievements) &&
    THERAPIST_CONQUERER_MILESTONES.includes(badgeCount);

  useEffect(() => {
    const localStorageKey = `hideAchievementBanner:${badgeCount}`;
    const isBannerDismissed = localStorage.getItem(localStorageKey) === "true";

    if (!isBannerDismissed && shouldShowAchievementsBanner) {
      toast.custom(
        (t) => {
          return (
            <AchievementsBanner
              badgeCount={badgeCount}
              dismissAchievementsBanner={() => {
                toast.remove(t.id);
                localStorage.setItem(localStorageKey, "true");
              }}
            />
          );
        },
        {
          duration: Infinity,
          position: "top-center",
        }
      );
    }
    return undefined;
  }, [badgeCount, shouldShowAchievementsBanner]);

  if (!user) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Loading />
      </Box>
    );
  }

  return (
    <Stack spacing={1} mb={6}>
      <div />
      {showShowImpactBar ? (
        <Box sx={{ mb: 3, alignItems: "center", width: "100%" }}>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              gap: 2,
            }}
          >
            <Typography sx={{ fontWeight: "700", fontSize: "28px" }}>
              {getGreeting()},{" "}
              {user?.preferred_first_name_input ?? user?.first_name} 👋
            </Typography>

            <ImpactBar clinicianEmail={user.email} />

            <Button
              endIcon={<ContentCopyIcon />}
              variant="nocdLightGrayButton"
              onClick={() =>
                navigator.clipboard
                  .writeText(user.zoom_meeting_link)
                  .then(() => toast.success("Zoom link copied to clipboard!"))
              }
            >
              Copy Zoom link
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "700", fontSize: "28px" }}>
            {getGreeting()},{" "}
            {user?.preferred_first_name_input ?? user?.first_name} 👋
          </Typography>

          <Divider
            sx={{
              margin: 0.5,
              flexGrow: 1,
              color: "#CBD5E1",
            }}
          />

          <Button
            endIcon={<ContentCopyIcon />}
            variant="nocdLightGrayButton"
            onClick={() =>
              navigator.clipboard
                .writeText(user.zoom_meeting_link)
                .then(() => toast.success("Zoom link copied to clipboard!"))
            }
          >
            Copy Zoom link
          </Button>
        </Box>
      )}

      <Grid container alignItems="stretch" spacing={1}>
        <Grid item xs={12} md={4}>
          <SessionCounter flex={1} />
        </Grid>

        <Grid item xs={12} md={8}>
          <ClinicianRecommendedHours flex={1} />
        </Grid>

        {!isSplitRole ? (
          <Grid item xs={12}>
            {flags?.enableMetricsWithTreatmentAdherence ? (
              <MetricsWithTreatmentAdherence flex={1} height="100%" />
            ) : (
              <Metrics flex={1} height="100%" />
            )}
          </Grid>
        ) : null}

        {flags?.enableThoughtOfTheDayOnDashboard ? (
          <Grid item xs={12} lg={12}>
            <ThoughtOfTheDay />
          </Grid>
        ) : null}

        <Grid item xs={12} lg={6}>
          <ClinicianTasks clinicianEmail={user.email} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <NewMessages />
        </Grid>

        <Grid container alignItems="stretch" spacing={1}>
          <Grid item xs={12} lg={7}>
            <Sessions flex={1} />
          </Grid>
          <Grid item xs={12} lg={5}>
            <QuarterWeekUtilization clinicianEmail={user.email} />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4}>
          <MemberActivity />
        </Grid>

        <Grid item xs={12} lg={8}>
          {PLAN_OF_SUCCESSION_STATUSES.includes(user.current_status) ||
          successionFlag?.has_flag ? (
            <Box component={Paper} p={3}>
              <Typography variant="h6" mb={2}>
                Plan of Succession
              </Typography>
              <PlanOfSuccessionMembers
                clinicianEmail={user.email}
                view="clinician"
              />
            </Box>
          ) : (
            <MemberFinder flex={1} />
          )}
        </Grid>
      </Grid>
      {shouldShowFeedbackForm && feedbackDialogueOpen ? (
        <div>
          <FeedbackDialog
            open={feedbackDialogueOpen}
            clinicianEmail={user.email}
            onClose={handleClose}
          />
        </div>
      ) : null}
    </Stack>
  );
}
