import { InfoOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Unit } from "../../clinician-progress/types";
import { formatValue } from "../../clinician-progress/utils";

const SimpleMetric = ({
  title,
  tooltipText,
  value,
  unit,
  timePeriod,
  onChangeTimePeriod,
  onViewDetails,
  isLoading,
  reachedGoal,
  secondary_title,
  onButtonToggle,
  showDetails = true,
  hideIcon = false,
  over100 = false,
}: {
  title: string;
  tooltipText: string;
  value: number | string;
  unit: Unit;
  onViewDetails?: () => void;
  timePeriod?: string;
  onChangeTimePeriod?: (timePeriod: string) => void;
  isLoading?: boolean;
  reachedGoal?: boolean;
  secondary_title?: string;
  onButtonToggle?: () => void;
  showDetails?: boolean;
  hideIcon?: boolean;
  over100?: boolean;
}) => {
  const renderValue = () => {
    if (isLoading) {
      return <CircularProgress size={24} />;
    }

    if (over100) {
      return "100+";
    }

    return value && unit ? formatValue(value, unit) : null;
  };

  const renderGoalMessage = () => {
    if (isLoading) {
      return <CircularProgress size={24} />;
    }

    return (
      <Typography variant="caption">
        You&apos;re doing a great job signing notes on time! Keep it up 🎉
      </Typography>
    );
  };

  return (
    <Box
      component={Paper}
      p={2}
      flex={1}
      minHeight={{ md: "130px", lg: "110px" }}
      height="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" mb={1}>
        <Typography variant="overline" alignItems="center">
          {secondary_title || title}
        </Typography>

        {tooltipText ? (
          <Tooltip
            title={
              <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
                {tooltipText}
              </Typography>
            }
            arrow
          >
            <InfoOutlined color="action" fontSize="small" />
          </Tooltip>
        ) : null}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="center">
        <Stack direction="row" spacing={1} alignItems="center">
          {onChangeTimePeriod ? (
            <TextField
              select
              value={timePeriod}
              size="small"
              onChange={(e) => onChangeTimePeriod(e.target.value)}
            >
              <MenuItem value="quarter">QTD</MenuItem>
              <MenuItem value="year">YTD</MenuItem>
              <MenuItem value="all">All</MenuItem>
            </TextField>
          ) : null}
          {!reachedGoal ? (
            <Typography variant="h5">{renderValue()}</Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                padding: reachedGoal ? 1 : 2,
              }}
            >
              {renderGoalMessage()}
            </Box>
          )}
        </Stack>

        {onViewDetails ? (
          <Box sx={{ ml: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={onViewDetails}
              endIcon={!hideIcon ? <OpenInNewIcon /> : null}
              sx={{ fontSize: "13px" }}
            >
              Details
            </Button>
          </Box>
        ) : null}

        {onButtonToggle ? (
          <Box sx={{ ml: 2 }}>
            <Button
              size="small"
              variant="outlined"
              endIcon={
                showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
              }
              onClick={onButtonToggle}
            >
              {showDetails ? "Hide details" : "Show details"}
            </Button>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box component={Paper} p={2} flex={1} height="100%">
      <Typography variant="overline">
        <Skeleton />
      </Typography>

      <Typography variant="h4">
        <Skeleton />
      </Typography>
    </Box>
  );
};

const Failure = ({ message }: { message: string }) => {
  return (
    <Box component={Paper} p={2} flex={1} height="100%">
      <Typography>Failed to load data: {message ?? "Unknown error"}</Typography>
    </Box>
  );
};

SimpleMetric.Loading = Loading;
SimpleMetric.Failure = Failure;

export default SimpleMetric;
