import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { Unit } from "../types";
import { formatValue } from "../utils";

const SimpleMilestoneMetric = ({
  icon,
  value,
  text,
  unit,
  isLoading,
}: {
  icon: React.ReactNode;
  value: number | string;
  text: string;
  unit: Unit;
  isLoading?: boolean;
}) => {
  return (
    <Box p={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        <Typography style={{ fontWeight: 700 }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            formatValue(value, unit)
          )}
        </Typography>
        : {text}
      </Stack>
    </Box>
  );
};

const Loading = () => {
  return (
    <Box p={2}>
      <Typography variant="body1">
        <Skeleton variant="text" width={555} />
      </Typography>
    </Box>
  );
};

const Failure = ({ message }: { message: string }) => {
  return (
    <Box p={2}>
      <Typography>Failed to load data: {message ?? "Unknown error"}</Typography>
    </Box>
  );
};

SimpleMilestoneMetric.Loading = Loading;
SimpleMilestoneMetric.Failure = Failure;

export default SimpleMilestoneMetric;
